import React from "react"
import { SEO } from "../../../components"
import { ProductJsonLd } from "../GoogleSEO"

const PRODUCT_NAME = "Life & Live Secure Benefit"
const DESCRIPTION =
  "Secure your family's future with Workerslife's Life and Live Secure Benefit. Comprehensive life insurance for financial protection after you pass."

const ProductSEO = (): JSX.Element => (
  <>
    <SEO title={PRODUCT_NAME} description={DESCRIPTION} />
    <ProductJsonLd
      name={PRODUCT_NAME}
      description={DESCRIPTION}
      lowPrice={100}
    />
  </>
)

export default ProductSEO
