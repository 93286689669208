import React from "react"
import styled from "styled-components"

import { CollapsibleList, FAQText } from "../../../components"

import { LIFE_AND_LIVE_SECURE_BENEFIT_FAQS as FAQS } from "../../../constants/faq"

const FAQs: React.FC = () => {
  return (
    <Container>
      <div>
        <h2>
          Frequently asked questions about the Life and Live Secure Product...
        </h2>
        <p>
          Everything you need to know about the Life and Live Secure product.
        </p>
      </div>
      <FAQList>
        {FAQS.map(({ title, text }, index) => (
          <CollapsibleList key={index} title={title}>
            {text.map((text, index) => (
              <FAQText key={`${index}`} text={{ id: index, ...text }} />
            ))}
          </CollapsibleList>
        ))}
      </FAQList>
    </Container>
  )
}

export default FAQs

const Container = styled.div`
  display: grid;
  gap: 46px;
  grid-template-columns: 100%;
  padding: 0 30px 100px 30px;

  h2 {
    font-size: 44px;
    line-height: 58px;
  }

  @media screen and (min-width: ${props => props.theme.breakpoints.lg}) {
    grid-template-columns: 340px 1fr;
    padding: 0 60px;
  }
`

const FAQList = styled.div`
  margin-top: -25px;

  .childContainer {
    @media only screen and (min-width: ${props => props.theme.breakpoints.sm}) {
      div {
        font-size: 22px;
        line-height: 34px;
      }
    }
  }
`
