import React from "react"
import styled from "styled-components"

import {
  Article,
  FAQs,
  Features,
  Conclusion,
  SEO,
} from "../../sections/products/life-and-live-secure"

const LifeAndLiveSecureBenefit: React.FC = () => (
  <PageWrapper>
    <SEO />
    <Article />
    <Features />
    <FAQs />
    <Conclusion />
  </PageWrapper>
)

export default LifeAndLiveSecureBenefit

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 40px;
`
