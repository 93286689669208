import { ISharpGatsbyImageArgs, StaticImage } from "gatsby-plugin-image"
import React from "react"

import { Slider } from "../../../components"

import { COVER_LINK, PRODUCT_COLOR } from "."

const PATH = "../../../assets/images/products/life-and-live-secure-benefit/"

const Features: React.FC = () => {
  const ImageProperties: {
    alt: string
    placeholder: ISharpGatsbyImageArgs["placeholder"]
    quality: number
  } = {
    alt: "",
    placeholder: "blurred",
    quality: 100,
  }

  return (
    <Slider
      title="Protecting Your Legacy"
      color={PRODUCT_COLOR}
      className="slider"
      coverType={COVER_LINK}
      content={[
        {
          image: (
            <StaticImage src={`${PATH}features-1.png`} {...ImageProperties} />
          ),
          topic: "Coverage Designed for Families",
          label:
            "The Life and Live Secure Benefit is not just insurance; it's a promise to ensure that your dependents have the financial support they need when you're no longer there. This coverage is designed to protect your legacy and provide for your loved ones.",
        },
        {
          image: (
            <StaticImage src={`${PATH}features-2.png`} {...ImageProperties} />
          ),
          topic: "Guaranteed Income for Your Loved Ones",
          label:
            "With this product, you can secure up to 80% of your monthly income guaranteed for your loved ones. This dependable income can last for a period of up to 10 years, ensuring that your family has the financial stability they need.",
        },
        {
          image: (
            <StaticImage src={`${PATH}features-3.png`} {...ImageProperties} />
          ),
          topic: "No Medical Examinations",
          label:
            "Applying for the Life and Live Secure Benefit is easy and convenient. You don't need to undergo any medical examinations, making the process hassle-free.",
        },
        {
          image: (
            <StaticImage src={`${PATH}features-4.png`} {...ImageProperties} />
          ),
          topic: "Affordable Premiums",
          label:
            "One of the key advantages of this product is its affordability. Premiums start at just R100 per month, depending on your income. This accessibility ensures that you can provide financial security for your family without straining your budget.",
        },
      ]}
    />
  )
}

export default Features
