import theme from "../../../theme"
export const PRODUCT_COLOR = theme.palette.custom.blue

import { CoverType } from "../../../elements/CoverButton"
export const COVER_LINK = CoverType.LIFE_AND_LIVE_SECURE

export { default as Article } from "./Article"
export { default as FAQs } from "./FAQs"
export { default as Features } from "./Features"
export { default as Conclusion } from "./Conclusion"
export { default as SEO } from "./SEO"
