import styled from "styled-components"
import React from "react"

import { ProductArticleExtraContent } from "../../../components"

import { COVER_LINK, PRODUCT_COLOR } from "."

const Article = (): JSX.Element => {
  return (
    <Container>
      <ProductArticleExtraContent
        color={PRODUCT_COLOR}
        title="Life and Live Secure Benefit"
        coverType={COVER_LINK}
        subTitle="Discover the Workerslife Life and Live Secure Benefit, a comprehensive life insurance product designed to secure up to 80% of your monthly income for your dependents in the event of your passing. Get covered in three simple steps, with no medical examinations required. Ensure the financial well-being of your family with affordable premiums starting at just R100 per month."
        topic="Protecting Your Loved Ones for a Brighter Future"
      >
        <p>
          Securing your family&apos;s financial future is one of the most
          meaningful legacies you can leave behind. At Workerslife, we
          understand the importance of ensuring that your dependents are
          financially cared for in the event of your passing. That&apos;s why we
          offer the Life and Live Secure Benefit, a comprehensive life insurance
          product that provides peace of mind for you and your loved ones.
        </p>
      </ProductArticleExtraContent>
    </Container>
  )
}

export default Article

const Container = styled.div`
  @media screen and (min-width: ${props => props.theme.breakpoints.lg}) {
    padding-bottom: 90px;
  }
`
